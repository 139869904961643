<template>
  <WsMain>
    Hi, {{$store.state.auth.currentUser.name}}
    <!-- {{$store.state.auth.currentUser}} -->
    <!-- {{$axios.defaults.headers}} -->
    <!-- {{$store.state.auth.scopes}} -->
  </WsMain>
</template>

<script>
export default {
  data() {
    return {
    };
  },
};
</script>